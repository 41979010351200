import { IonContent, IonPage, IonImg, useIonViewDidLeave } from "@ionic/react";
import { useState, useMemo, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import React from "react";
import { LanguageContent, Pdfs } from "../types";
import { useLanguage } from "../contexts/LanguageContext";
import { usePdf } from "../contexts/PdfContext";

export const languageContent: { [key: string]: LanguageContent } = {
  id: {
    // Slide 0: Introduction content
    title: "Silabus Keamanan",
    intro: `Bertemu dengan orang baru pasti menyenangkan selama kamu mengutamakan keamanan!

Simak petunjuk lengkap ini untuk menikmati pengalaman online dating dengan berhati-hati, baik secara online atau offline. Pembahasannya lengkap, mulai dari Kesadaran, Batasan & Consent, dan berbagai hal lainnya.`,
    modules: [
      // Slide 1: Safety Seminar module
      {
        title: "Pusat Keamanan",
        intro: "Keamanan yang utama",
        buttonLabel: "Stay Safe!",
        pdfs: {
          desktop: "/assets/pdf/ID_Safety.pdf",
          mobile: "/assets/pdf/ID_Safety(m).pdf",
        },
        // Desktop image for Safety Seminar
        imgSrc: (_language: string) =>
          `assets/img/id-safety-seminar-thumb-01.jpg`,
        // Mobile image for Safety Seminar
        imgSrcMobile: (_language: string) =>
          `assets/img/id-safety-seminar-thumb-01-mobile.jpg`,
        bgColor: "#fd5466",
      },
      // Slide 2: Consent Crash Course module
      {
        title: "Belajar Tentang Consent",
        intro:
          "Panduan tentang cara menetapkan dan menyampaikan batasan kamu dengan jelas!",
        buttonLabel: "Dapatkan consent!",
        pdfs: {
          desktop: "/assets/pdf/ID_Consent.pdf",
          mobile: "/assets/pdf/ID_Consent(m).pdf",
        },
        // Single image used for both desktop and mobile views for consent
        imgSrc: (_language: string) => `assets/img/safety-consent-thumb-01.png`,
        bgColor: "#186ac4",
      },
    ],
  },
  vn: {
    title: "Hội thảo về An toàn",
    intro: `Bắt sóng tần số với người mới quen luôn là điều khá hào hứng nhưng nó chỉ thực sự thú vị chỉ khi bạn đặt sự an toàn của bản thân lên hàng đầu! Cùng khám phá toàn bộ hướng dẫn bên dưới về cách tận dụng tối đa trải nghiệm tương hợp mà vẫn đảm bảo được sự an toàn của bạn khi trực tuyến lẫn ngoài đời. Chúng tôi đề cập đến vấn đề an toàn về Nhận thức, Ranh giới và Sự Đồng thuận cùng vô số những vấn đề khác.`,
    modules: [
      {
        title: "Chuyên đề về An toàn",
        intro:
          "Không phải con gà hay quả trứng, mà sự an toàn của bạn phải được đặt lên đầu tiên!",
        buttonLabel: "Giữ an toàn",
        pdfs: {
          desktop: "/assets/pdf/VN_Safety.pdf",
          mobile: "/assets/pdf/VN_Safety(m).pdf",
        },
        imgSrc: (_language: string) =>
          `assets/img/vn-safety-seminar-thumb-01.jpg`,
        imgSrcMobile: (_language: string) =>
          `assets/img/vn-safety-seminar-thumb-01-mobile.jpg`,
        bgColor: "#fd5466",
      },
      {
        title: "Khóa học cơ bản về sự đồng thuận",
        intro: "Hướng dẫn về cách đánh dấu ranh giới và giao tiếp rõ ràng!",
        buttonLabel: "Hiểu về Đồng thuận",
        pdfs: {
          desktop: "/assets/pdf/VN_Consent.pdf",
          mobile: "/assets/pdf/VN_Consent(m).pdf",
        },
        imgSrc: (_language: string) => `assets/img/safety-consent-thumb-01.png`,
        bgColor: "#186ac4",
      },
    ],
  },
  th: {
    title: "หลักสูตรความปลอดภัย",
    intro: `การรู้สึกดีกับคนใหม่เป็นเรื่องน่าตื่นเต้นอยู่แล้ว แต่จะดีกว่าถ้าคุณให้ความสำคัญกับความปลอดภัยเป็นอันดับแรก

ลองดูคำแนะนำที่เรารวมทุกเรื่องเกี่ยวกับวิธีปฏิบัติอย่างไร ให้ได้รับประสบการณ์ที่ดีในการจับคู่ Match มากที่สุด และสามารถระวังเรื่องความปลอดภัยของตัวเองได้ทั้งบนโลกออนไลน์และออฟไลน์ ในบทเรียนนี้เราจะพูดถึงความปลอดภัยแบบครอบคลุมในเรื่อง ก. ข. ค. (การตระหนักรู้  ขอบเขต ความยินยอม) และอีกหลายประเด็น`,
    modules: [
      {
        title: "สัมมนาเรื่องความปลอดภัย",
        intro:
          "ไม่สำคัญว่าไก่หรือไข่จะมาก่อนกัน เพราะความปลอดภัยของคุณมาก่อนเสมอ",
        buttonLabel: "ปลอดภัยไว้ก่อน",
        pdfs: {
          desktop: "/assets/pdf/TH_Safety.pdf",
          mobile: "/assets/pdf/TH_Safety(m).pdf",
        },
        imgSrc: (_language: string) =>
          `assets/img/th-safety-seminar-thumb-01.jpg`,
        imgSrcMobile: (_language: string) =>
          `assets/img/th-safety-seminar-thumb-01-mobile.jpg`,
        bgColor: "#fd5466",
      },
      {
        title: "คอร์สเรียนเรื่องความยินยอมฉบับเร่งด่วน",
        intro:
          "คำแนะนำในการหาทางว่า ทำอย่างไรถึงจะมีขอบเขตที่พอดี และสามารถสื่อสารกันได้อย่างชัดเจน",
        buttonLabel: "การยินยอมที่คุณเลือกได้",
        pdfs: {
          desktop: "/assets/pdf/TH_Consent.pdf",
          mobile: "/assets/pdf/TH_Consent(m).pdf",
        },
        imgSrc: (_language: string) => `assets/img/safety-consent-thumb-01.png`,
        bgColor: "#186ac4",
      },
      // New Thai only Module: Let's Talk Consent
      {
        title: "คู่มือ “Let’s Talk Consent”",
        intro:
          "สร้างความสัมพันธ์ที่ดีต่อใจด้วยคู่มือ “Let’s Talk Consent” ฉบับภาษาไทยจากความร่วมมือของ Tinder และ Thaiconsent",
        buttonLabel: "เริ่มเลย",
        pdfs: {
          desktop: "/assets/pdf/TH_Lets_talk_consent.pdf",
        },
        imgSrc: (_language: string) =>
          `assets/img/lets-talk-consent-thumb-01.png`,
        bgColor: "#ff00ae",
      },
    ],
  },
  en: {
    title: "Safety Syllabus",
    intro: `Vibing with new people is always exciting but only when you keep your safety first! 

Check out this all-in-one guide on how to make the most of your matching experience while also staying careful, both online and offline. We cover the ABC’s of safety (Awareness, Boundaries & Consent!) along with so much more.`,
    modules: [
      {
        title: "Safety Seminar",
        intro: "Neither the chicken nor the egg, your safety comes first!",
        buttonLabel: "Stay Safe",
        pdfs: {
          desktop: "/assets/pdf/Safety.pdf",
          mobile: "/assets/pdf/Safety(m).pdf",
        },
        imgSrc: (_language: string) => `assets/img/safety-seminar-thumb-01.jpg`,
        imgSrcMobile: (_language: string) =>
          `assets/img/safety-seminar-thumb-01-mobile.jpg`,
        bgColor: "#fd5466",
      },
      {
        title: "Consent Crash Course",
        intro: "A guide on how to navigate boundaries and communicate clearly!",
        buttonLabel: "Get Consent",
        pdfs: {
          desktop: "/assets/pdf/Consent.pdf",
          mobile: "/assets/pdf/Consent(m).pdf",
        },
        imgSrc: (_language: string) => `assets/img/safety-consent-thumb-01.png`,
        bgColor: "#186ac4",
      },
    ],
  },
};

const SafetySyllabus: React.FC = () => {
  const { language } = useLanguage();
  const { setModalVisible, setDesktopPdf, setMobilePdf } = usePdf();
  const [swiper, setSwiper] = useState<SwiperCore>();

  const content = useMemo(
    () => languageContent[language] || languageContent.en,
    [language]
  );

  const handleButtonClick = useCallback(
    (pdfs: Pdfs) => {
      setDesktopPdf(pdfs.desktop);
      setMobilePdf(pdfs.mobile || pdfs.desktop);
      setModalVisible(true);
    },
    [setDesktopPdf, setMobilePdf, setModalVisible]
  );

  useIonViewDidLeave(() => {
    swiper?.slideTo(0);
  }, [swiper]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <Swiper
          initialSlide={0}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          onSwiper={(swiper) => setSwiper(swiper)}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          centeredSlides={true}
          breakpoints={{
            768: {
              slidesPerView: 1.2,
              spaceBetween: -20,
            },
            1024: {
              slidesPerView: 1.4,
              spaceBetween: -120,
            },
          }}
        >
          <SwiperSlide>
            <div className="container-inner">
              <div className="d-flex">
                <h1
                  className="container-title"
                  style={{ background: "#ff00c2", color: "#fff" }}
                >
                  {content.title}
                </h1>
                <div
                  className="container-content"
                  style={{
                    background: "#fff",
                    color: "#303030",
                    paddingTop: "0.1rem",
                  }}
                >
                  <p>{content.intro}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {content.modules.map((module, index) => (
            <SwiperSlide key={index}>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1
                    className="container-title"
                    style={{ background: module.bgColor, color: "#fff" }}
                  >
                    {module.title}
                  </h1>
                  <div
                    className="container-content"
                    style={{ background: "#fff", color: "#303030" }}
                  >
                    <p className="hide-desktop">{module.intro}</p>
                    <IonImg
                      className="hide-mobile"
                      src={module.imgSrc(language)}
                    ></IonImg>
                    <IonImg
                      className="hide-desktop"
                      src={
                        module.imgSrcMobile
                          ? module.imgSrcMobile(language)
                          : module.imgSrc(language)
                      }
                    ></IonImg>
                    <div className="flex">
                      <p className="hide-mobile" style={{ maxWidth: "550px" }}>
                        {module.intro}
                      </p>
                      <button
                        onClick={() => handleButtonClick(module.pdfs)}
                        style={{ color: "#fff", background: module.bgColor }}
                      >
                        {module.buttonLabel}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(SafetySyllabus);
