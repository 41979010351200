import React from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { useLanguage, LanguageEnum } from "../contexts/LanguageContext";

export function LanguageSelect() {
  const { language, setLanguage } = useLanguage();

  const handleLanguageChange = (newLanguage: LanguageEnum) => {
    setLanguage(newLanguage);
  };

  return (
    <IonSelect
      interface="popover"
      value={language}
      onIonChange={(e) => handleLanguageChange(e.detail.value as LanguageEnum)}
      style={{ position: "absolute", right: "1rem", zIndex: 99 }}
    >
      <IonSelectOption value={LanguageEnum.EN}>English</IonSelectOption>
      <IonSelectOption value={LanguageEnum.ID}>Bahasa</IonSelectOption>
      <IonSelectOption value={LanguageEnum.TH}>Thai</IonSelectOption>
      <IonSelectOption value={LanguageEnum.VN}>Vietnamese</IonSelectOption>
    </IonSelect>
  );
}
