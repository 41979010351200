import React, { useMemo, useCallback } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";

interface HomeContent {
  title: string;
  intro: string;
  buttonLabel: string;
}

const homeContent: { [key: string]: HomeContent } = {
  id: {
    title: "Apa sih kerennya School of Swipe™?",
    intro: `Yuk kenali definisi swipe (geser), match (jodoh), ghosting, sampai thirsting, dan banyak hal lainnya, ada berbagai ilmu berkencan lainnya yang bisa kamu pelajari. Selangkah lebih maju dan tingkatkan wawasanmu supaya semakin mantap ngedeketin si dia.
Sudah siap belajar berkencan? Yuk, kelasnya sudah mulai.`,
    buttonLabel: "Ayo!",
  },
  vn: {
    title: "School of Swipe™ có gì thú vị?",
    intro: `Từ vuốt trái và phải, tương hợp, ghosting, đến thả thính và hơn thế nữa, mỗi bước đi trong hành trình hẹn hò là một bài học. Sẵn sàng dẫn đầu xu thế và nâng cấp các kỹ năng của bạn để chinh phục đối phương ngay thôi.
Bạn cần một khóa học hẹn hò? Bắt đầu lớp học ngay thôi.`,
    buttonLabel: "Cùng bắt đầu nào",
  },
  th: {
    title: "เรื่องตื่นเต้นในคลาสเรียน แห่งการปัดขวา™?",
    intro: `บทเรียนการออกเดทตั้งแต่เริ่มจับคู่ Match หรือโดนเทแบบงง ๆ
ไปจนถึงการจีบแบบมือโปร เรียนรู้เคล็ดลับดี ๆ
เพื่ออัพสกิลการเดทให้ล้ำหน้ากว่าใคร
และเพิ่มทักษะการใช้แอพเตรียมพร้อมก่อนปัดขวา ทักไอ้ต้าวน่ารักที่ถูกใจ
อยากเรียนรู้บทเรียนการออกเดทแล้วใช่มั้ย?`,
    buttonLabel: "ไปเรียนกันเลย",
  },
  en: {
    title: "What’s the hype around School of Swipe™?",
    intro: `With all the sliding, matching, ghosting, thirsting and so much more, there’s a dating lesson around every corner. Get ahead of the curve and level up your skills so that you’re fully prepped to hit up that cutie.
Need a dating lesson? Class is in session.`,
    buttonLabel: "Let’s go",
  },
};

const Home: React.FC = () => {
  const history = useHistory();
  const { language } = useLanguage();

  const content = useMemo(() => {
    return homeContent[language] || homeContent.en;
  }, [language]);

  const btnHandler = useCallback(() => {
    history.push("/app-academy");
  }, [history]);

  return (
    <IonPage className="home">
      <IonContent
        fullscreen
        className="container"
        style={{ whiteSpace: "pre-line" }}
      >
        <h1>{content.title}</h1>
        <p style={{ lineHeight: "1.2" }}>{content.intro}</p>
        <button
          className="white-btn"
          onClick={btnHandler}
          style={{ zIndex: "105", marginTop: "1rem" }}
        >
          {content.buttonLabel}
        </button>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Home);
