import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  ReactNode,
} from "react";

export type PdfContextType = {
  modalVisible: boolean;
  desktopPdf: string;
  mobilePdf: string;
  setDesktopPdf: (pdf: string) => void;
  setMobilePdf: (pdf: string) => void;
  setModalVisible: (visible: boolean) => void;
};

const PdfContext = createContext<PdfContextType | undefined>(undefined);

export const PdfProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [desktopPdf, setDesktopPdf] = useState<string>("");
  const [mobilePdf, setMobilePdf] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      desktopPdf,
      mobilePdf,
      modalVisible,
      setDesktopPdf,
      setMobilePdf,
      setModalVisible,
    }),
    [desktopPdf, mobilePdf, modalVisible]
  );

  return (
    <PdfContext.Provider value={contextValue}>{children}</PdfContext.Provider>
  );
};

// Custom hook to use the PDF context.
export const usePdf = (): PdfContextType => {
  const context = useContext(PdfContext);
  if (!context) {
    throw new Error("usePdf must be used within a PdfProvider");
  }
  return context;
};

export { PdfContext };
