import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Header from "./components/Header";
import SplashScreen from "./components/SplashScreen";
import DownloadBtn from "./components/DownloadBtn";
import { ParallaxProvider } from "react-scroll-parallax";
import { LanguageProvider } from "./contexts/LanguageContext";
import { Route } from "react-router-dom";
import PdfViewer from "./components/PdfViewer";
import { PdfProvider } from "./contexts/PdfContext";

// Ionic and custom CSS imports...
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "font-proxima-nova/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "animate.css";
import "./theme/style.css";

setupIonicReact();

function App() {
  return (
    <ParallaxProvider>
      <PdfProvider>
        <IonApp>
          <IonReactRouter>
            <LanguageProvider>
              <IonRouterOutlet>
                <Route>
                  <SplashScreen />
                  <Header />
                </Route>
              </IonRouterOutlet>
            </LanguageProvider>
          </IonReactRouter>
          <DownloadBtn />
        </IonApp>
        <PdfViewer />
      </PdfProvider>
    </ParallaxProvider>
  );
}

export default App;
