import React, { useMemo, useCallback } from "react";
import { IonImg } from "@ionic/react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { usePdf } from "../contexts/PdfContext";

const PdfViewer: React.FC = () => {
  const { modalVisible, desktopPdf, mobilePdf, setModalVisible } = usePdf();

  // Close handler using useCallback
  const handleClose = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  // Handlers for opening PDFs
  const openDesktopPdf = useCallback(() => {
    window.open(desktopPdf);
  }, [desktopPdf]);

  const openMobilePdf = useCallback(() => {
    window.open(mobilePdf);
  }, [mobilePdf]);

  // Memoize the CSS class for the viewer
  const viewerClass = useMemo(
    () => `pdf-viewer ${modalVisible ? "open" : ""}`,
    [modalVisible]
  );

  return (
    <div className={viewerClass}>
      <div className="btn-holder">
        <div>
          <IonImg
            className="download-btn hide-desktop"
            src="assets/img/download.png"
            onClick={openDesktopPdf}
          />
          <IonImg
            className="download-btn hide-mobile"
            src="assets/img/download.png"
            onClick={openMobilePdf}
          />
          <IonImg
            className="close-btn"
            src="assets/img/close-btn.png"
            onClick={handleClose}
          />
        </div>
      </div>
      <div>
        <Document file={desktopPdf} className="hide-mobile">
          <Page pageNumber={1} height={1600} />
        </Document>
        <Document file={mobilePdf} className="hide-desktop">
          <Page pageNumber={1} height={1600} />
        </Document>
      </div>
    </div>
  );
};

export default React.memo(PdfViewer);
