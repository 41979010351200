import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
  ReactNode,
} from "react";
import { useLocation, useHistory } from "react-router";

export enum LanguageEnum {
  EN = "en",
  ID = "id",
  TH = "th",
  VN = "vn",
  BA = "ba",
}

type LanguageContextType = {
  language: LanguageEnum;
  setLanguage: (value: LanguageEnum) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  language: LanguageEnum.EN, // Default language set to English
  setLanguage: () => {},
});

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.EN);

  // Set language from URL param and query only on initial page load or mount
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const langParam = params.get("lang") as LanguageEnum;

    // Check for language code in the path
    const pathSegments = location.pathname.split("/");
    const langInPath = pathSegments[1] as LanguageEnum;

    if (langInPath && Object.values(LanguageEnum).includes(langInPath)) {
      const effectiveLang =
        langInPath === LanguageEnum.BA ? LanguageEnum.ID : langInPath;
      setLanguage(effectiveLang);
      // Remove the language code from the path and update the URL
      pathSegments.splice(1, 1);
      const newPath = pathSegments.join("/");
      params.set("lang", effectiveLang);
      history.replace({ pathname: newPath, search: params.toString() });
    } else if (langParam && Object.values(LanguageEnum).includes(langParam)) {
      setLanguage(langParam === LanguageEnum.BA ? LanguageEnum.ID : langParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLanguageCallback = useCallback(
    (value: LanguageEnum) => {
      setLanguage(value);
      const params = new URLSearchParams(location.search);
      params.set("lang", value);
      history.replace({ search: params.toString() });
    },
    [location.search, history]
  );

  const contextValue = useMemo(
    () => ({ language, setLanguage: setLanguageCallback }),
    [language, setLanguageCallback]
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
