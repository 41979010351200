import { IonContent, IonPage, IonImg, useIonViewDidLeave } from "@ionic/react";
import { useState, useMemo, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import React from "react";
import { LanguageContent, Pdfs } from "../types";
import { useLanguage } from "../contexts/LanguageContext";
import { usePdf } from "../contexts/PdfContext";

export const languageContent: { [key: string]: LanguageContent } = {
  id: {
    title: "Kelas Aplikasi",
    intro: `Akademi Aplikasi membantu kamu untuk lebih paham cara menggunakan Tinder secara lebih baik. Akademi ini akan memberi tahu semua fitur keren yang mungkin kamu lewatkan (yup, menyukai dan bisa cocok sama orang dari negara yang berbeda itu ada fiturnya) dan juga tentang fitur keren Jelajah. Setelah kamu menjelajah sesi ini, jangan kaget kalau banyak match baru yang bisa kamu dapatkan!`,
    modules: [
      // Module 1: The Profile Project (Slide 2)
      {
        title: "Bikin profil kamu glow-up",
        intro:
          "Solusi dari setiap permasalahan profil kamu - dari memilih foto sampai menulis bio yang tepat!",
        buttonLabel: "Yuk, jadi profile-pro",
        pdfs: {
          desktop: "/assets/pdf/ID_TheProfileProject.pdf",
          mobile: "/assets/pdf/ID_TheProfileProject(m).pdf",
        },
        // This function returns the image source for the profile project in Indonesian
        imgSrc: (language: string) =>
          `assets/img/id-aa-profile-pro-thumb-01.png`,
        bgColor: "#ff00ae",
      },
      // Module 2: Explore Explained (Slide 3)
      {
        title: "Tentang Jelajah",
        intro: "Pilih minatmu dan bertemu dengan mereka yang sama dengan kamu.",
        buttonLabel: "Jelajah bareng yuk!",
        pdfs: {
          desktop: "/assets/pdf/ID_Explore.pdf",
          mobile: "/assets/pdf/ID_Explore(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/id-aa-explore-thumb-01.png`,
        bgColor: "#26e8c8",
      },
      // Module 3: Flexin’ Our Features (Slide 4)
      {
        title: "Kenalan dengan Fitur Tinder",
        intro:
          "Fitur handal yang bakal bikin pengalaman kamu di Tinder jadi makin seru.",
        buttonLabel: "Cek lebih lanjut!",
        pdfs: {
          desktop: "/assets/pdf/ID_Features.pdf",
          mobile: "/assets/pdf/ID_Features(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/id-aa-flex-thumb-01.png`,
        bgColor: "#298cfb",
      },
      // Module 4: How To Avoid Dry Convos (Slide 5)
      {
        title: "Cara Menghindari Obrolan Yang Garing",
        intro:
          "Kuasai ice-breaking dan bikin percakapan seru - tingkatkan kemampuan ngobrol kamu dengan tips-tips ini!",
        buttonLabel: "Mulai mengetik",
        pdfs: {
          desktop: "/assets/pdf/ID_AvoidDryConvo.pdf",
          mobile: "/assets/pdf/ID_AvoidDryConvo(m).pdf",
        },
        imgSrc: (language: string) =>
          `assets/img/id-aa-dry-convos-thumb-01.png`,
        bgColor: "#ff5599",
      },
    ],
  },
  vn: {
    title: "Học viện ứng dụng",
    intro: `Học viện ứng dụng đảm bảo rằng bạn biết cách sử dụng Tinder cũng như hiểu rõ trái tim mình muốn gì. Học viện ứng dụng giới thiệu tới bạn tất cả những tính năng thú vị mà bạn có thể đã bỏ lỡ (đúng vậy, việc thích và tương hợp từ một quốc gia khác hoàn toàn là một vấn đề quan trọng) cùng với tính năng mới hấp dẫn nhất trên ứng dụng của chúng tôi - tính năng Khám phá. Sau khi lướt qua phần này, đừng ngạc nhiên vì đảm bảo bạn sẽ bỏ túi thêm hàng loạt tương hợp`,
    modules: [
      // Module 1: The Profile Project (Slide 2)
      {
        title: "Hồ sơ cá nhân",
        intro:
          "Giải pháp cho mọi vấn đề về hồ sơ Tinder - từ việc chọn ảnh phù hợp cho đến lời giới thiệu hoàn hảo!",
        buttonLabel: "Trở thành chuyên gia",
        pdfs: {
          desktop: "/assets/pdf/VN_TheProfileProject.pdf",
          mobile: "/assets/pdf/VN_TheProfileProject(m).pdf",
        },
        imgSrc: (language: string) =>
          `assets/img/vn-aa-profile-pro-thumb-01.png`,
        bgColor: "#ff00ae",
      },
      // Module 2: Explore Explained (Slide 3)
      {
        title: "Giải thích về Tính năng Khám phá",
        intro:
          "Lựa chọn sở thích, đam mê của bạn và kết nối với những người cùng gu.",
        buttonLabel: "Bắt đầu khám phá",
        pdfs: {
          desktop: "/assets/pdf/VN_Explore.pdf",
          mobile: "/assets/pdf/VN_Explore(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/vn-aa-explore-thumb-01.png`,
        bgColor: "#26e8c8",
      },
      // Module 3: Flexin’ Our Features (Slide 4)
      {
        title: "Các tính năng nổi bật trên Tinder",
        intro:
          "Những tính năng hấp dẫn nhất chắc chắn mang tới cho bạn một trải nghiệm Tinder thú vị bậc nhất.",
        buttonLabel: "Khám phá ngay",
        pdfs: {
          desktop: "/assets/pdf/VN_Features.pdf",
          mobile: "/assets/pdf/VN_Features(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/vn-aa-flex-thumb-01.png`,
        bgColor: "#298cfb",
      },
      // Module 4: How To Avoid Dry Convos (Slide 5)
      {
        title: "Làm thế nào để rắc muối cho cuộc trò chuyện thêm lôi cuốn",
        intro:
          "Hãy trở thành người phá băng và mở đầu cuộc trò chuyện. Nâng cấp kỹ năng trò chuyện của bạn với những gợi ý thú vị này nhé!",
        buttonLabel: "Bắt đầu nhắn tin",
        pdfs: {
          desktop: "/assets/pdf/VN_AvoidDryConvo.pdf",
          mobile: "/assets/pdf/VN_AvoidDryConvo(m).pdf",
        },
        imgSrc: (language: string) =>
          `assets/img/vn-aa-dry-convos-thumb-01.png`,
        bgColor: "#ff5599",
      },
    ],
  },
  th: {
    title: "แอพอะคาเดมี่",
    intro: `แอพอะคาเดมี่จะช่วยให้คุณรู้จักการใช้  Tinder ให้ดีขึ้นพอ ๆ กับที่คุณรู้จักการค้นหาหัวใจตัวเอง แอพอะคาเดมี่จะพาคุณไปรู้จักกับฟีเจอร์เจ๋ง ๆ ทั้งหมดซึ่งคุณอาจจะไม่เคยรู้ว่ามีมาก่อน เช่น การ Like และการจับคู่ Match กับคนที่อยู่ต่างประเทศ พร้อมกับการพาทัวร์ชมหน้า Explore บนแอพ Tinder บอกเลยว่าหลังจากที่คุณได้เข้าไปอ่านดูแล้วก็ไม่ต้องแปลกใจเลย ถ้าคุณจะมีคู่ Match เพิ่มมากขึ้น`,
    modules: [
      // Module 1: The Profile Project (Slide 2)
      {
        title: "โปรเจกต์การสร้างโปรไฟล์",
        intro:
          "ทางออกของทุกปัญหาบนหน้าโปรไฟล์ ตั้งแต่การเลือกรูปสุดปั๊วะ ไปจนถึงการสร้างประวัติส่วนตัวสุดปัง",
        buttonLabel: "สร้างโปรไฟล์แบบมือโปร",
        pdfs: {
          desktop: "/assets/pdf/TH_TheProfileProject.pdf",
          mobile: "/assets/pdf/TH_TheProfileProject(m).pdf",
        },
        imgSrc: (language: string) =>
          `assets/img/th-aa-profile-pro-thumb-01.png`,
        bgColor: "#ff00ae",
      },
      // Module 2: Explore Explained (Slide 3)
      {
        title: "ทำความรู้จัก",
        intro: "เลือกแพสชั่นของคุณ เพื่อ Match กับคนที่ใช่",
        buttonLabel: "เริ่มกันเลย",
        pdfs: {
          desktop: "/assets/pdf/TH_Explore.pdf",
          mobile: "/assets/pdf/TH_Explore(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/th-aa-explore-thumb-01.png`,
        bgColor: "#26e8c8",
      },
      // Module 3: Flexin’ Our Features (Slide 4)
      {
        title: "ฟีเจอร์ที่อยากอวด",
        intro: "ฟีเจอร์สุดฮ็อตที่จะช่วยให้คุณมีประสบการณ์สุดคูลในการใช้ Tinder",
        buttonLabel: "ไปดูกันเลย",
        pdfs: {
          desktop: "/assets/pdf/TH_Features.pdf",
          mobile: "/assets/pdf/TH_Features(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/th-aa-flex-thumb-01.png`,
        bgColor: "#298cfb",
      },
      // Module 4: How To Avoid Dry Convos (Slide 5)
      {
        title: "วิธีเลี่ยงการคุยแบบจืดชืด",
        intro: "มาเป็นฝ่ายทักครัช อัพสกิลการแชท เริ่มคุยก่อนด้วยคำแนะนำของเรา",
        buttonLabel: "เริ่มแชทกันเลย",
        pdfs: {
          desktop: "/assets/pdf/TH_AvoidDryConvo.pdf",
          mobile: "/assets/pdf/TH_AvoidDryConvo(m).pdf",
        },
        imgSrc: (language: string) =>
          `assets/img/th-aa-dry-convos-thumb-01.png`,
        bgColor: "#ff5599",
      },
    ],
  },
  en: {
    title: "App Academy",
    intro: `The App Academy makes sure you know your way around Tinder just as well as you know your way around hearts. It walks you through all the cool features that you may have missed (yup, liking and matching from a different country is totally a thing) along with a tour of our hottest new venue in-app - Explore. After browsing through this section, don’t be surprised if you’re collecting matches left and right!`,
    modules: [
      // Module 1: The Profile Project (Slide 2)
      {
        title: "The Profile Project",
        intro:
          "Your solution to every profile problem - from picking pictures that werk to the perfect bio!",
        buttonLabel: "Become a Pro",
        pdfs: {
          desktop: "/assets/pdf/TheProfileProject.pdf",
          mobile: "/assets/pdf/TheProfileProject(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/aa-profile-pro-thumb-01.png`,
        bgColor: "#ff00ae",
      },
      // Module 2: Explore Explained (Slide 3)
      {
        title: "Explore Explained",
        intro: "Pick your passion and connect with people who match your vibe.",
        buttonLabel: "Start Explore-ing",
        pdfs: {
          desktop: "/assets/pdf/Explore.pdf",
          mobile: "/assets/pdf/Explore(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/aa-explore-thumb-01.png`,
        bgColor: "#26e8c8",
      },
      // Module 3: Flexin’ Our Features (Slide 4)
      {
        title: "Flexin’ Our Features",
        intro:
          "The hottest features that make sure you have the coolest Tinder experience.",
        buttonLabel: "Check Them Out",
        pdfs: {
          desktop: "/assets/pdf/Features.pdf",
          mobile: "/assets/pdf/Features(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/aa-flex-thumb-01.png`,
        bgColor: "#298cfb",
      },
      // Module 4: How To Avoid Dry Convos (Slide 5)
      {
        title: "How To Avoid Dry Convos",
        intro:
          "Become the ice-breaker and conversation maker - level up your texting game with these pointers!",
        buttonLabel: "Start Typing",
        pdfs: {
          desktop: "/assets/pdf/AvoidDryConvo.pdf",
          mobile: "/assets/pdf/AvoidDryconvo(m).pdf",
        },
        imgSrc: (language: string) => `assets/img/aa-dry-convos-thumb-01.png`,
        bgColor: "#ff5599",
      },
    ],
  },
};

const AppAcademy: React.FC = () => {
  const { language } = useLanguage();
  const { setModalVisible, setDesktopPdf, setMobilePdf } = usePdf();
  const [swiper, setSwiper] = useState<SwiperCore>();

  const content = useMemo(
    () => languageContent[language] || languageContent.en,
    [language]
  );

  const handleButtonClick = useCallback(
    (pdfs: Pdfs) => {
      setDesktopPdf(pdfs.desktop);
      setMobilePdf(pdfs.mobile || pdfs.desktop);
      setModalVisible(true);
    },
    [setDesktopPdf, setMobilePdf, setModalVisible]
  );

  useIonViewDidLeave(() => {
    swiper?.slideTo(0);
  }, [swiper]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <Swiper
          initialSlide={0}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          onSwiper={(swiper) => setSwiper(swiper)}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          centeredSlides={true}
          breakpoints={{
            768: {
              slidesPerView: 1.2,
              spaceBetween: -20,
            },
            1024: {
              slidesPerView: 1.4,
              spaceBetween: -120,
            },
          }}
        >
          <SwiperSlide>
            <div className="container-inner">
              <div className="d-flex">
                <h1
                  className="container-title"
                  style={{ background: "#ff5733", color: "#fff" }}
                >
                  {content.title}
                </h1>
                <div
                  className="container-content"
                  style={{
                    background: "#fff",
                    color: "#303030",
                    paddingTop: "0.1rem",
                  }}
                >
                  <p>{content.intro}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {content.modules.map((module, index) => (
            <SwiperSlide key={index}>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1
                    className="container-title"
                    style={{ background: module.bgColor, color: "#fff" }}
                  >
                    {module.title}
                  </h1>
                  <div
                    className="container-content"
                    style={{ background: "#fff", color: "#303030" }}
                  >
                    <p className="hide-desktop">{module.intro}</p>
                    <IonImg
                      className="hide-mobile"
                      src={module.imgSrc(language)}
                    ></IonImg>
                    <div className="flex">
                      <p className="hide-mobile" style={{ maxWidth: "550px" }}>
                        {module.intro}
                      </p>
                      <button
                        onClick={() => handleButtonClick(module.pdfs)}
                        style={{ color: "#fff", background: module.bgColor }}
                      >
                        {module.buttonLabel}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(AppAcademy);
