import React, { useState, useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonImg,
  IonGrid,
  IonIcon,
} from "@ionic/react";
import Home from "../pages/home";
import AppAcademy from "../pages/app-academy";
import SafetySyllabus from "../pages/safety-syllabus";
import IRLDating from "../pages/irl-dating";
import { chevronBack, chevronForward } from "ionicons/icons";
import { LanguageSelect } from "./LanguageSelect";
import { useLanguage } from "../contexts/LanguageContext";

const menuItems = [
  {
    key: "home",
    paths: {
      en: "home",
      id: "beranda",
      ba: "beranda",
      th: "หน้าหลัก",
      vn: "trang chủ",
    },
    path: "/home",
  },
  {
    key: "appAcademy",
    paths: {
      en: "app academy",
      id: "KELAS APLIKASI",
      ba: "KELAS APLIKASI",
      th: "แอพอะคาเดมี่",
      vn: "Học viện ứng dụng",
    },
    path: "/app-academy",
  },
  {
    key: "irlDating",
    paths: {
      en: "irl dating 101",
      id: "KENCAN IRL 101",
      ba: "KENCAN IRL 101",
      th: "วิธีการออกเดท 101",
      vn: "Hẹn hò trực tiếp 101",
    },
    path: "/irl-dating",
  },
  {
    key: "safetySyllabus",
    paths: {
      en: "safety syllabus",
      id: "SILABUS KEAMANAN",
      ba: "SILABUS KEAMANAN",
      th: "หลักสูตรความปลอดภัย",
      vn: "Khóa học An toàn",
    },
    path: "/safety-syllabus",
  },
];

const Header: React.FC = () => {
  let [zIndex, setZIndex] = useState("99");
  const { language } = useLanguage();

  setTimeout(() => {
    setZIndex("101");
  }, 3000);

  const tabButtons = useMemo(
    () =>
      menuItems.map((item) => (
        <IonTabButton
          key={item.key}
          tab={item.key}
          href={`${item.path}`}
          className="no-ripple"
        >
          <IonTitle color="light">{item.paths[language]}</IonTitle>
        </IonTabButton>
      )),
    [language]
  );

  return (
    <>
      <IonGrid className="header" style={{ zIndex: zIndex }}>
        <LanguageSelect />
        <IonHeader
          className="ion-no-border ion-padding ion-justify-content-center"
          mode="md"
        >
          <IonToolbar>
            <IonImg src="assets/img/logo.png" className="header-logo"></IonImg>
          </IonToolbar>
        </IonHeader>
        <IonTabs className="header-nav">
          <IonRouterOutlet>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/app-academy">
              <AppAcademy />
            </Route>
            <Route path="/safety-syllabus">
              <SafetySyllabus />
            </Route>
            <Route path="/irl-dating">
              <IRLDating />
            </Route>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="top" mode="md" className="header-nav-bar">
            {tabButtons}
          </IonTabBar>
        </IonTabs>
        <IonIcon icon={chevronBack} className="navbar-chevron-left"></IonIcon>
        <IonIcon
          icon={chevronForward}
          className="navbar-chevron-right"
        ></IonIcon>
      </IonGrid>
    </>
  );
};

export default React.memo(Header);
