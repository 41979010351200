import { IonContent, IonPage, IonImg, useIonViewDidLeave } from "@ionic/react";
import { useState, useMemo, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import React from "react";
import { LanguageContent, Pdfs } from "../types";
import { useLanguage } from "../contexts/LanguageContext";
import { usePdf } from "../contexts/PdfContext";

export const languageContent: { [key: string]: LanguageContent } = {
  id: {
    // Slide 0 (Introduction)
    title: "Kencan IRL 101",
    intro:
      "Ini kelas buat kalian yang sudah pro di online dating tapi masih suka panik saat harus ketemuan langsung. Ini cara gampangnya gimana menghadapi transisi hubungan dari online ke dunia nyata dengan santai, nggak pakai cemas!",
    // Slide 1 (Module)
    modules: [
      {
        title: "Kelas Pertama - Kencan Pertama",
        // The intro text appears in both hide-desktop and hide-mobile elements.
        intro:
          "Hilangkan kegugupan kamu dengan tips kencan pertama anti gagal!",
        buttonLabel: "Kencan yuk",
        pdfs: {
          desktop: "/assets/pdf/ID_FirstClassFirstDates.pdf",
          mobile: "/assets/pdf/ID_FirstClassFirstDates(m).pdf",
        },
        // Returns the image path for the module
        imgSrc: (_language: string) => `assets/img/id-irl-fcfc-thumb-01.png`,
        bgColor: "#26e8c8",
      },
    ],
  },
  vn: {
    title: "Hẹn hò trực tiếp 101",
    intro:
      "Thông tin dành cho tất cả những ai đã có một cuộc trò chuyện thú vị qua mạng nhưng vẫn chưa biết phải làm gì tiếp theo khi bắt đầu chuyển sang gặp gỡ đối phương ngoài đời thực. Những hướng dẫn dưới đây sẽ giúp bạn dễ dàng chuyển từ trò chuyện trực tuyến sang gặp mặt trực tiếp một cách thoải mái mà không tạo ra bất kỳ lo lắng, áp lực nào!",
    modules: [
      {
        title: "Bài học vỡ lòng: Buổi hẹn đầu tiên",
        intro:
          "Tạm biệt những lo lắng bằng những lời khuyên bất bại cho buổi hẹn đầu tiên!",
        buttonLabel: "Hẹn hò thôi nào",
        pdfs: {
          desktop: "/assets/pdf/VN_FirstClassFirstDates.pdf",
          mobile: "/assets/pdf/VN_FirstClassFirstDates(m).pdf",
        },
        imgSrc: (_language: string) => `assets/img/vn-irl-fcfc-thumb-01.png`,
        bgColor: "#26e8c8",
      },
    ],
  },
  th: {
    title: "วิธีการออกเดท 101",
    intro:
      "สำหรับคนที่กล้าออกเดทออนไลน์ แต่กลับกลัวจนสติหลุดเมื่อต้องออกเดทแบบออฟไลน์ นี่คือวิธีง่ายๆ ที่เราอยากแนะนำในการเปลี่ยนจากการเดทออนไลน์ไปเป็นเดทแบบนัดพบในชีวิตจริงให้ราบรื่นไร้กังวล",
    modules: [
      {
        title: "คลาสแรกกับเดทแรก",
        intro:
          "ส่งจูบบอกลา บ๊ายบายความกังวลทั้งหลาย ด้วยเคล็ดลับเดทแรกแบบเน้นๆ ไม่มีพลาด",
        buttonLabel: "ไปเดทกัน",
        pdfs: {
          desktop: "/assets/pdf/TH_FirstClassFirstDates.pdf",
          mobile: "/assets/pdf/TH_FirstClassFirstDates(m).pdf",
        },
        imgSrc: (_language: string) => `assets/img/th-irl-fcfc-thumb-01.png`,
        bgColor: "#26e8c8",
      },
    ],
  },
  en: {
    title: "IRL Dating 101",
    intro:
      "This one’s for all of you who cracked the code to online dating but then low-key freak out when it starts shifting offline. Here’s an easy walkthrough on how to make the smooth transition from online to IRL in a chill, anxiety-free way!",
    modules: [
      {
        title: "First Class: First Dates",
        intro:
          "Kiss those nerves goodbye with these first date tips that never fail!",
        buttonLabel: "Let’s Date",
        pdfs: {
          desktop: "/assets/pdf/FirstClassFirstDates.pdf",
          mobile: "/assets/pdf/FirstClassFirstDates(m).pdf",
        },
        imgSrc: (_language: string) => `assets/img/irl-fcfc-thumb-01.png`,
        bgColor: "#26e8c8",
      },
    ],
  },
};

const IRLDating: React.FC = () => {
  const { language } = useLanguage();
  const { setModalVisible, setDesktopPdf, setMobilePdf } = usePdf();
  const [swiper, setSwiper] = useState<SwiperCore>();

  const content = useMemo(
    () => languageContent[language] || languageContent.en,
    [language]
  );

  const handleButtonClick = useCallback(
    (pdfs: Pdfs) => {
      setDesktopPdf(pdfs.desktop);
      setMobilePdf(pdfs.mobile || pdfs.desktop);
      setModalVisible(true);
    },
    [setDesktopPdf, setMobilePdf, setModalVisible]
  );

  useIonViewDidLeave(() => {
    swiper?.slideTo(0);
  }, [swiper]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <Swiper
          initialSlide={0}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          onSwiper={(swiper) => setSwiper(swiper)}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          centeredSlides={true}
          breakpoints={{
            768: {
              slidesPerView: 1.2,
              spaceBetween: -20,
            },
            1024: {
              slidesPerView: 1.4,
              spaceBetween: -120,
            },
          }}
        >
          <SwiperSlide>
            <div className="container-inner">
              <div className="d-flex">
                <h1
                  className="container-title"
                  style={{ background: "#00ff7e", color: "#fff" }}
                >
                  {content.title}
                </h1>
                <div
                  className="container-content"
                  style={{
                    background: "#fff",
                    color: "#303030",
                    paddingTop: "0.1rem",
                  }}
                >
                  <p>{content.intro}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {content.modules.map((module, index) => (
            <SwiperSlide key={index}>
              <div className="container-inner design-one">
                <div className="d-flex">
                  <h1
                    className="container-title"
                    style={{ background: module.bgColor, color: "#fff" }}
                  >
                    {module.title}
                  </h1>
                  <div
                    className="container-content"
                    style={{ background: "#fff", color: "#303030" }}
                  >
                    <p className="hide-desktop">{module.intro}</p>
                    <IonImg
                      className="hide-mobile"
                      src={module.imgSrc(language)}
                    ></IonImg>
                    <div className="flex">
                      <p className="hide-mobile" style={{ maxWidth: "550px" }}>
                        {module.intro}
                      </p>
                      <button
                        onClick={() => handleButtonClick(module.pdfs)}
                        style={{ color: "#fff", background: module.bgColor }}
                      >
                        {module.buttonLabel}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(IRLDating);
